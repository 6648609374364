@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700&family=Polysans:wght@400;700&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  backdrop-filter: blur(40px);
  box-shadow: 0px 28px 45px -1px #0000000D;
  background: linear-gradient(0deg, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.8)),
    linear-gradient(123.55deg, rgba(0, 0, 0, 0.2) 11.74%, rgba(0, 0, 0, 0.05) 87.03%);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Polysans', sans-serif;
}

p {
  font-family: 'Inter', sans-serif;
}
 

.animate-scroll {
  animation: scroll 20s linear infinite;
}
@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}
 
@keyframes letterTransition {
  0%, 100% { color: inherit; scale:1; }
  50% { color: rgb(21, 19, 19) ; scale:1 ; }
}

.powered-by-animation {
  display: inline-block;
}

.powered-by-animation span {
  display: inline-block;
  animation: letterTransition 1s cubic-bezier(0.68, -0.55, 0.27, 1.55) 1;
}

.powered-by-animation span:nth-child(1) { animation-delay: 0s; }
.powered-by-animation span:nth-child(2) { animation-delay: 0.1s; }
.powered-by-animation span:nth-child(3) { animation-delay: 0.2s; }
.powered-by-animation span:nth-child(4) { animation-delay: 0.3s; }
.powered-by-animation span:nth-child(5) { animation-delay: 0.4s; }
.powered-by-animation span:nth-child(6) { animation-delay: 0.5s; }
.powered-by-animation span:nth-child(7) { animation-delay: 0.6s; }
.powered-by-animation span:nth-child(8) { animation-delay: 0.7s; }
.powered-by-animation span:nth-child(9) { animation-delay: 0.8s; }
.powered-by-animation span:nth-child(10) { animation-delay: 0.9s; }
 
.modal-open {
  overflow: hidden;
}
